<template>
    <div class="page-wrap" v-loading="loading">
      <div class="page-aside">
        <el-menu
          background-color="#1a2031"
          text-color="#fff"
          active-text-color="#4d85d9"
          :default-active='$route.path'
          :router="true"
        >
          <el-menu-item index="/modelManage/divisors">因子池</el-menu-item>
          <el-menu-item index="/modelManage/modelTuning">模型调优</el-menu-item>
          <el-menu-item index="/modelManage/modelUpdate">模型更新</el-menu-item>
          <el-menu-item index="/modelManage/forecastUpdate">预测更新</el-menu-item>
        </el-menu>
      </div>
      <div class="page-main flex-column">
        <SubNav :nav="subNav" :defaultActive="modelFreq" @change="subNavChange"></SubNav>
        <div class="main padding-20"><router-view :modelFreq='modelFreq' :productList="productList"></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>
import SubNav from '@/components/SubNav.vue'
export default {
  name: 'Entry',
  components: { SubNav },
  data() {
    return {
     subNav:[{name:'周度预测模型',value:'week'},{name:'月度预测模型',value:'month'}],
     modelFreq:'week',
     productList:[],
     loading:false,
    }
  },
  created(){
   this.getProducts()
  },
  methods:{
    // getProducts(){
    //   setTimeout(()=>{
    //     this.productList=[{productName:'液碱',productId:12354,targetId:3},{productName:'丙烯',productId:12605,targetId:5},{productName:'甘油',productId:12581,targetId:6},{productName:'丙烷',productId:12513,targetId:8}]
    //     this.checkProAuth()
    //   },1000)
    // },
    getProducts(){
      this.loading=true
      let request=this.$instance.get('/manage/getProductPower',{
          params: {
           ...this.$store.state.basicParams,
           moduleCode:'mo_xing_guan_li'
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.productList=res.data.info
          this.checkProAuth()
        }else{
          this.$router.replace({
            path:'/error',
            query:{
              redirect:this.$route.fullPath
            }
          })
        }
      })
      request.catch(()=>{
        this.loading=false
        this.$router.replace({
          path:'/error',
          query:{
            redirect:this.$route.fullPath
          }
        })
      })
      return request
    },
    checkProAuth(){
      if(!this.productList.length){
        this.$alert(`您暂无模型管理的产品权限`, '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {
          this.$router.replace({
            path:'/'
          })
        })
      }
    },
   subNavChange(val){
    this.modelFreq=val
   }
    
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
